import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseButtonComponent } from '../base-button/base-button.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-link[routerLink], app-link[href]',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './link.component.html',
  styleUrls: ['../base-button/base-button.component.scss'],
})
export class LinkComponent extends BaseButtonComponent {
  @Input() routerLink: string | undefined = undefined;
  @Input() fragment: string | undefined = undefined;
  @Input() href: string | undefined = undefined;
  @Input() target: string | undefined = undefined;
}
