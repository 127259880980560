<ng-template #label>
  <ng-content></ng-content>
</ng-template>

@if (href) {
  <a [ngClass]="classNames" [href]="href" [target]="target">
    <ng-container *ngTemplateOutlet="label"></ng-container>
  </a>
} @else if (routerLink) {
  <a [ngClass]="classNames" [routerLink]="routerLink" [fragment]="fragment">
    <ng-container *ngTemplateOutlet="label"></ng-container>
  </a>
} @else {
  <ng-container *ngTemplateOutlet="label"></ng-container>
}
